/* src/index.css */

/* 基本样式重置 */
body,
html,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

* {
  box-sizing: border-box;
}
