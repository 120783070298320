/* src/ThemeDialog.css */

.theme-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1000;
  }
  
  .theme-dialog {
    background-color: white;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-height: 50vh;
    border-radius: 15px 15px 0 0;
    animation: slide-up 0.3s ease-out;
    overflow: hidden;
  }
  
  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .dialog-content {
    padding: 20px;
    text-align: center;
  }
  
  .dialog-content h2 {
    margin-bottom: 15px;
    font-size: 20px;
  }
  
  .search-input {
    font-weight: bold;
    text-align: center;
    height: 45px;
    width: calc(100% - 20px);
    padding: 10px 10px;
    margin-bottom: 0px;
    border-radius: 30px;
    border: 0px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    outline: none;
    transition: border-color 0.3s;
  }
  .search-input::placeholder{
    font-weight: bold;
    color:black;
  }
  
  .search-input:focus {
    border-color: #333;
  }
  
  .theme-list {
    list-style: none;
    padding: 10px;
    margin: 0;
    max-height: 35vh;
    overflow-y: auto;
  }
  
  .theme-list li {
    margin: 10px 0;
    padding: 10px 15px;
    background-color: #fff;
    border-radius: 35px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .theme-list li:hover {
    background-color: #f0f0f0;
  }
  
  .theme-item {
    display: flex;
    flex-direction: column; /* 主题名和作者名分行显示 */
    align-items: flex-start; /* 左对齐 */
  }
  
  .theme-name {
    font-weight: bold;
    font-size: 18px;
    color: #333;
    margin-bottom: 4px; /* 间距 */
  }
  
  .theme-author {
    font-size: 14px;
    color: #666;
  }
  .authorization-count {
    font-size: 14px;
    color: #666;
  }
  
  .theme-details {
    display: flex; /* 使用 flexbox 使子元素水平排列 */
    gap: 20px; /* 调整子元素之间的间距 */
    margin-top: 5px; /* 给作者和授权数量添加一点上边距 */
  }
  
  .theme-author,
  .authorization-count {
    white-space: nowrap; /* 防止内容换行 */
  }
  
  
  .dialog-content button {
    background-color: #fff;
    border-radius: 25px;
    width: 50%;
    color: black;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    outline: none;
    transition: background-color 0.3s;
  }
  
  .dialog-content button:hover {
    background-color: black;
    color: #fff;
  }
  