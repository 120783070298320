/* src/App.css */

.App {
  text-align: center;
  overflow: hidden; /* 禁止滚动 */
  margin: 0; /* 去掉默认外边距 */
  padding: 0; /* 去掉默认内边距 */
  height: 100vh; /* 设置高度为视口高度 */
  width: 100vw; /* 设置宽度为视口宽度 */
}

.App-header {
  background-color: #fff;
  width: 100%; /* 使顶部区域铺满整个屏幕 */
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; /* 水平对齐方式 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 顶部阴影 */
  color: #000; /* 黑色字体 */
  padding: 10px;
  position: fixed; /* 固定在顶部 */
  top: 0;
  left: 0;
  z-index: 10; /* 确保其在最上层 */
}

.Header-logo {
  height: 40px;
  margin-right: 10px; /* 控制 logo 和文本之间的间隙 */
}
.Header-logos {
  
  height: 45px;
  width: 45px; /* 确保宽高一致 */
  border-radius: 50%; /* 圆形样式 */
  margin-right: 10px; /* Logo 与右侧有一定间隙 */
  object-fit: cover; /* 确保图片内容完全展示 */
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 垂直方向居左对齐 */
}

.login-status {
  font-size: 16px;
  font-weight: bold;
}

.authorization-info {
  font-size: 12px; /* 字体大小改为12 */
  color: #000; /* 字体颜色改为黑色 */
  margin-top: 4px; /* 与用户名的间隙 */
}

.Dashboard {
  margin-top: 0px; /* 确保内容与固定头部有一定距离 */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  height: 100px;
  margin-top: 150px;
}
.App-logos {
  margin-top: 150px;
  height: 100px;
}

.App-title {
  font-size: 18px;
  color: #333;
  margin-top: 10px;
}

.form-container {
  margin-top: 40px; /* 控制与顶部的距离 */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-field {
  width: 300px;
  height: 50px;
  margin-bottom: 15px;
  margin-top: 5px;
  padding: 10px;
  border-radius: 25px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  color: #333;
  background-color: #fff;
  text-align: center;
  font-weight: bold;
}

.input-field::placeholder {
  color: #000; /* 提示文字颜色 */
}

.input-field:focus {
  outline: none; /* 去掉输入框的边框 */
}

.login-button {
  width: 145px;
  height: 50px;
  margin-top: 10px;
  border-radius: 25px;
  border: none;
  background-color: #fff;
  color: #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.login-button:active {
  transform: scale(1); /* 取消按钮按下时的变色效果 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
/* 过渡动画样式 */
.transition-container {
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* 渐变过渡效果 */
}

.transition-container.fade-out {
  opacity: 0; /* 淡出效果 */
}

.authorize-button:active {
  transform: scale(0.98); /* 点击时缩小 */
}

/* 对话框遮罩层 */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

/* 对话框容器 */
.dialog-container {
  background-color: #fff;
  width: 100%;
  max-width: 400px;
  border-radius: 12px 12px 0 0;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  animation: slideUp 0.3s ease-out;
}

/* 底部滑入动画 */
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* 主题列表样式 */
.theme-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.theme-list li {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.theme-list li:hover {
  background-color: #ddd;
}
.button-container {
  display: flex;
  gap: 10px; /* 按钮之间的间距 */
}

/* 关闭按钮样式 */
.close-dialog-button {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 8px;
  background-color: #3498db;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
